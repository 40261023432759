import type { WalletInfoInterface } from '../types/wallet';
import type { Config, Return } from '~hooks/use-request';
import useRequest from '~hooks/use-request';

export const useWalletInfo = (
  objectId: string,
  config?: Config<WalletInfoInterface>,
): Return<WalletInfoInterface, unknown> => {
  return useRequest(
    objectId
      ? {
          url: `/wallets/${objectId}`,
        }
      : undefined,
    config,
  );
};
