import { proxied } from 'radash';
import numeral from 'numeral';
import { CurrencyEnum } from './constant/etc';

export const currencyFormat = (
  balance: number | undefined,
  currency: CurrencyEnum | undefined,
  format?: string,
): string => {
  const currencyFormats = proxied((prop) => {
    switch (prop) {
      case CurrencyEnum.VND:
        return numeral(balance)
          .format(format || '0,0.000')
          .replace(/,/g, '.');
      case CurrencyEnum.THB:
        return numeral(balance).format(format || '0,0.00');
      default:
        return '';
    }
  });
  return currencyFormats[currency || CurrencyEnum.THB];
};
