import type { Currency } from '@dinero.js/currencies';
import { VND } from '@dinero.js/currencies';
import { dinero, toDecimal, convert } from 'dinero.js';

const VND_1000: Currency<number> = {
  code: 'VND_1000',
  base: 10,
  exponent: 0,
};

const vndRates = {
  VND_1000: { amount: 1, scale: 3 },
  VND: { amount: 1000 },
};

const convertValue = (value: number, currency: Currency<number>): number => {
  return Math.trunc(value * 10 ** currency.exponent);
};

export const convertVnd1000ToVnd = (amount: number): string => {
  const receivedAmount = dinero({
    amount: convertValue(amount, VND_1000),
    currency: VND_1000,
  });
  const actualAmount = convert(receivedAmount, VND, vndRates);
  return toDecimal(actualAmount);
};

export const convertVndToVnd1000 = (amount: number): string => {
  const receivedAmount = dinero({
    amount: convertValue(amount, VND),
    currency: VND,
  });
  const actualAmount = convert(receivedAmount, VND_1000, vndRates);
  return toDecimal(actualAmount);
};
