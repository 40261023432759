import type { FC } from 'react';
import { currencyFormat } from '@packages/currency';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { convertCurrency } from '~libs/convert-currency';
import { DEFAULT_CURRENCY } from '~constants/etc';

const BalanceWidget: FC<{
  className?: string;
  balance?: number | undefined;
}> = ({ className, balance }) => {
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet?.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const balanceValue = convertCurrency(balance, currency);
  const currentBalance = currencyFormat(balanceValue, currency);

  return <span className={className}>{currentBalance}</span>;
};

export default BalanceWidget;
