import { cn } from 'class-merge';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import type { ForwardRefRenderFunction, ReactNode } from 'react';
import type { ModalRefInterface } from 'hooks/use-dialog';
import { Dialog } from './index';
import { ButtonColors } from '~constants/etc';
import { Button } from '~components/button';
import appConfig from '~build-config/config.json';

export interface DialogConfigs {
  title?: ReactNode;
  icon?: ReactNode;
  maskable?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  closable?: boolean;
  className?: string;
  children: ReactNode;
  onOk?: () => void;
  onClose?: () => void;
  okText?: string | ReactNode;
  closeText?: string | ReactNode;
  okClassName?: string;
  closeClassName?: string;
}

export interface HookDialogProps {
  configs: DialogConfigs;
}

const HookDialog: ForwardRefRenderFunction<
  ModalRefInterface,
  HookDialogProps
> = ({ configs }, ref) => {
  const {
    closable,
    maskable,
    size = 'xs',
    className,
    icon,
    title,
    children,
    okClassName,
    okText,
    closeClassName,
    closeText,
    onOk,
    onClose,
  } = configs;
  const [visible, setVisible] = useState<boolean>(true);

  const handleClose = (): void => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    destroy: handleClose,
  }));

  return (
    <Dialog
      className={cn(
        'flex flex-col p-0',
        size === 'xxl' ? 'rounded-none' : 'rounded-lg',
        className,
      )}
      closable={closable}
      maskable={maskable}
      onClose={handleClose}
      open={visible}
      size={size}
    >
      {title ? (
        <div className="flex gap-2 font-bold">
          <span className="mt-[7px]">{icon}</span>
          {title}
        </div>
      ) : null}
      {children}
      <div className="mt-4 space-y-2">
        {okText ? (
          <Button
            block
            className={okClassName}
            color={
              appConfig.customButtonColor?.okTextDialog || ButtonColors.DEFAULT
            }
            onClick={() => {
              if (typeof onOk === 'function') {
                onOk();
              }
              handleClose();
            }}
          >
            {okText}
          </Button>
        ) : null}
        {closeText ? (
          <Button
            block
            className={cn('text-color h-[50px] font-normal', closeClassName)}
            custom
            onClick={() => {
              if (typeof onClose === 'function') {
                onClose();
              }
              handleClose();
            }}
          >
            {configs.closeText}
          </Button>
        ) : null}
      </div>
    </Dialog>
  );
};

export default forwardRef(HookDialog);
