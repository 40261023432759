import type { ReactElement } from 'react';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { ConfirmFilled, ErrorFilled, InfoFilled } from '@packages/icons-react';
import { useTranslation } from 'react-i18next';
import usePatchElement from './use-patch-element';
import type { DialogConfigs } from '~components/dialog/hook-dialog';
import HookDialog from '~components/dialog/hook-dialog';

interface ElementsHolderRef {
  patchElement: ReturnType<typeof usePatchElement>[1];
}

export interface ModalRefInterface {
  destroy: () => void;
}

const ElementsHolder = memo(
  forwardRef<ElementsHolderRef>((_props, ref) => {
    const [elements, patchElement] = usePatchElement();
    useImperativeHandle(
      ref,
      () => ({
        patchElement,
      }),
      [],
    );
    return <>{elements}</>;
  }),
);

type DialogFn = (config: DialogConfigs) => void;
type DestroyFn = () => void;

interface Dialog {
  info: DialogFn;
  content: DialogFn;
  error: DialogFn;
  confirm: DialogFn;
  destroy: DestroyFn;
}

export const useDialog = (): [Dialog, ReactElement] => {
  let uuid = 0;
  const holderRef = useRef<ElementsHolderRef>(null);
  const modalRef = useRef<ModalRefInterface>(null);
  const { t } = useTranslation('buttons');

  const getDialogContent = useCallback(
    (
      withDefault: (config: DialogConfigs) => DialogConfigs,
      ref:
        | ((instance: ModalRefInterface | null) => void)
        | React.RefObject<ModalRefInterface>
        | null
        | undefined,
    ) =>
      function hookDialogContent(config: DialogConfigs) {
        uuid += 1;
        const modal = (
          <HookDialog
            configs={withDefault(config)}
            key={`dialog-${uuid}`}
            ref={ref}
          />
        );
        holderRef.current?.patchElement(modal);
      },
    [],
  );

  const contents = useMemo<Dialog>(
    () => ({
      info: getDialogContent(
        (props) => ({
          ...props,
          icon: <InfoFilled />,
          className: 'p-4',
          okText: t('btn-confirm'),
        }),
        modalRef,
      ),
      content: getDialogContent(
        (props) => ({
          ...props,
          className:
            'bg-default-color bg-opacity-30 flex items-center justify-center w-full rounded-none p-4',
        }),
        modalRef,
      ),
      error: getDialogContent(
        (props) => ({
          ...props,
          icon: <ErrorFilled />,
          className: 'p-4',
          closeClassName: 'bg-red-900',
          closeText: t('btn-close'),
        }),
        modalRef,
      ),
      confirm: getDialogContent(
        (props) => ({
          ...props,
          icon: <ConfirmFilled />,
          className: 'p-4',
          okText: t('btn-confirm'),
          closeText: t('btn-cancel'),
        }),
        modalRef,
      ),
      destroy: () => modalRef.current?.destroy(),
    }),
    [],
  );
  return [contents, <ElementsHolder key="element-holder" ref={holderRef} />];
};
